export default {
  methods: {
    transform(item) {
      return {
        ...item,
        created_at: this.dateFromISO8601(item.created_at),
        quantity: item.pcs * item.boxes
      };
    }
  }
};

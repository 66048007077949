import request from './request'

export default {
  buildURL(url, opts, search) {
    let args = []

    if (opts.page) {
      args.push(`page=${opts.page}&size=${opts.itemsPerPage}`)
    }

    if (search?.length > 0) {
      args.push(`search=${search}`)
    }

    if (opts.filters) {
      opts.filters.forEach(f => {
        console.log('f:', f)
        let field = f.field
        if (field == 'order') {
          field = 'order__order_no'
        }
        args.push(`${field}${f.operation}${f.value}`)
        console.log('args', args)
      })
    }

    let sort = []
    for (let i = 0; i < opts.sortBy?.length; i++) {
      let sign = ''
      if (i < opts.sortDesc?.length && opts.sortDesc[i]) {
        sign += '-'
      }
      let field = opts.sortBy[i].replace('.', '__')

      if (field == 'operator') {
        field = 'operator__username'
      }
      if (field == 'order') {
        field = 'order__order_no'
      }

      sort.push(sign + field)
    }
    if (sort.length > 0) {
      args.push(`ordering=${sort.join(',')}`)
    }

    if (args.length == 0) {
      return url
    }

    url = `${url}?${args.join('&')}`

    return url
  },

  async post(item) {
    console.log('post warehouse item: ', item)
    const result = await request.request(`/api/warehouse/`, 'post', item, {})

    console.log('result: ', result)
    return result
  },

  async getItems(opts, search) {
    let url = this.buildURL(`/api/warehouse/`, opts, search)

    console.log('url:', url)
    let rsp = await request.request(url, 'get', {}, {})
    return [rsp.data.results, rsp.data.count]
  },

  async patch(item) {
    console.log('patch warehouse item: ', item)
    let id = item.id
    const result = await request.request(
      `/api/warehouse/${id}/`,
      'patch',
      item,
      {}
    )

    console.log('result: ', result)
    return result
  },

  async delete(item) {
    console.log('delete warehouse item: ', item)
    let id = item.id
    const result = await request.request(
      `/api/warehouse/${id}/`,
      'delete',
      {},
      {}
    )

    console.log('result: ', result)
    return result
  },

  async excel(opts, search) {
    console.log('Generate warehouse.xlsx')
    let url = `/api/warehouse/excel/`
    url = this.buildURL(url, opts, search)

    console.log('url:', url)

    await request.download(url, 'warehouse.xlsx')
  }
}

<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12" sm="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('warehouse.item.order')"
              >
                <v-autocomplete
                  ref="order"
                  v-model="item.order"
                  :loading-orders="loadingOrders"
                  :items="ordersToSelect"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('warehouse.item.order')"
                  :hint="$t('select-hint')"
                  persistent-hint
                  :clearable="isNew"
                  :disabled="!isNew"
                  dense
                  :search-input.sync="searchOrder"
                  cache-items
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <validation-provider v-slot="{ errors }" rules="required|min:1">
                <v-text-field
                  v-model="item.pcs"
                  :label="$t('warehouse.item.pcs')"
                  type="number"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('warehouse.item.boxes')"
              >
                <v-text-field
                  v-model="item.boxes"
                  :label="$t('warehouse.item.boxes')"
                  type="number"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="item.location"
                :label="$t('warehouse.item.location')"
                dense
                @keydown.esc="onCancel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('warehouse.item.operator')"
              >
                <v-text-field
                  v-model="item.operator"
                  :label="$t('warehouse.item.operator')"
                  type="string"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('warehouse.item.status')"
              >
                <v-text-field
                  v-model="item.status"
                  :label="$t('warehouse.item.status')"
                  type="string"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="onSave()">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="onCancel()">
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import WarehouseItemService from '@/services/WarehouseService.js';
import OrderService from '@/services/OrderService.js';
import warehouseMixin from './warehouseMixin';

export default {
  components: {},
  mixins: [warehouseMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      item: Object.assign({}, this.value),
      orders: [],
      loadingOrders: false,
      searchOrder: null,
      originalItem: null
    };
  },
  computed: {
    isNew() {
      return !(this.item.id > 0);
    },
    title() {
      if (this.isNew) {
        return this.$t('warehouse.item.new');
      }
      return this.$t('warehouse.item.edit');
    },
    ordersToSelect() {
      return this.orders.map(o => {
        return {
          value: o.order_no,
          text: `${o.order_no} - ${o.client_ro?.name} - ${o.when_created}`
        };
      });
    }
  },
  watch: {
    value: {
      async handler(val) {
        this.item = Object.assign({}, val);
      },
      deep: true
    },
    searchOrder(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      const s = val?.split(' ');
      if (s?.length > 0) {
        val = s[0];
        this.$nextTick(() => {
          this.searchOrder = val;
        });
      }
      val && val != this.item.order && this.loadOrders(val);
    }
  },
  async activated() {
    console.log('activated; item:', this.item);
    await this.init();
  },
  methods: {
    async init() {
      console.log('init(); item:', this.item);
      this.focusInput();
      await this.loadOrders(this.item.order);
      this.originalItem = Object.assign({}, this.item);
    },

    async onCancel() {
      this.$refs.observer.reset();
      this.$emit('close');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        console.log('validation failed');
        return;
      }

      try {
        let obj = Object.assign({}, this.item);
        if (obj.due_date) {
          obj.due_date = obj.due_date.toISOString().substr(0, 10);
        }

        if (this.isNew) {
          let result = await WarehouseItemService.post(obj);
          let item = this.transform(result.data);
          this.$root.$emit('warehouseitem_added', item); // use central event hub to notify siblign container
        } else {
          let result = await WarehouseItemService.patch(obj);
          let item = this.transform(result.data);
          this.$root.$emit('warehouseitem_updated', item); // use central event hub to notify siblign container
        }
        this.$emit('close');
      } catch (err) {
        this.showError(this, err);
      }
    },
    focusInput() {
      this.$refs.order.focus();
    },

    onError(err) {
      this.showError(this, err);
    },

    async loadOrders(searchOrder) {
      if (!searchOrder || searchOrder.length == 0) {
        return;
      }
      console.log('loadingOrders orders: ', searchOrder);
      this.loadingOrders = true;
      OrderService.getOrders(
        {
          page: 1,
          itemsPerPage: 40,
          sortBy: ['when_created'],
          sortDesc: [true]
        },
        searchOrder
      )
        .then(response => {
          let [orders] = response;
          this.orders = orders.map(this.transform);
          console.log('orders:', this.orders);

          if (
            this.searchOrder == null &&
            this.orders.length == 1 &&
            this.orders[0].order_no == this.item.order
          ) {
            this.searchOrder = this.orders[0].order_no;
          }
        })
        .catch(err => {
          this.showError(this, err);
        })
        .finally(() => {
          this.loadingOrders = false;
        });
    }
  }
};
</script>

<style></style>

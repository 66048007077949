<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
    />
  </keep-alive>
</template>

<script>
import list from '@/views/dashboard/components/warehouse/warehouseList';
import form from '@/views/dashboard/components/warehouse/warehouseForm';

export default {
  components: {},
  data: function() {
    return {
      current: list,
      params: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    onNew() {
      this.current = form;
      this.params = {
        value: {
          id: '',
          created_at: new Date(),
          updated_at: new Date()
        }
      };
    },
    onEdit(item) {
      this.current = form;
      this.params = {
        value: item
      };
    },
    backToList() {
      this.current = list;
      this.params = {};
    }
  }
};
</script>

<style></style>
